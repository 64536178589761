<template>
  <div class="manage-property-add animatedBox">
    <div class="container fluid">
      <h1 class="p-2 mb-2">Add New Property</h1>

      <PropertyForm
        :isEdit="false"
        :form.sync="property"
        showOwnerCode
        @submit="submitForm"
      ></PropertyForm>
    </div>
  </div>
</template>

<script>
import { areaUnitType } from "@/enums";
import propertyModel from "@/models/property";
import PropertyForm from "@/modules/Property/components/PropertyForm";

export default {
  name: "manage-property-add",
  components: {
    PropertyForm
  },
  mixins: [],
  computed: {},
  data() {
    return {
      pricePerUnitOrigin: "Built Up",

      // property data
      property: {
        mainPhoto: [],
        gallery: [],
        latitude: "",
        longitude: "",
        tenureType: [],
        propertyFacilities: [],
        propertyAmenities: [],
        propertyCustomCategories: [],
        ownerPropertyCode: "",
        propertyOwner: {},
        areaUnitType: Object.values(areaUnitType)[0],

        country: "",
        state: "",
        city: "",
        area: "",
        building: "",

        allowPartnership: true,
        propertyCommission: {},
        offerToPurchase: {},
        offerToRent: {},
        propertyTags: [],
        hashtag: "",
        remark: "",

        // Auction
        auctionTime: "",
        auctionDate: "",
        auctionVenue: "",
        auctionTypeId: "",
        assignee: "",
        auctioneer: "",
        lawyer: "",
        assignor: "",
        deposit: "",
        proclamationOfSale: []
      },

      propertyStatus: ""
    };
  },
  created: function () {},
  mounted: function () {},
  beforeDestroy() {},
  watch: {},
  methods: {
    updateAddress(data) {
      this.property.countryId = data.country?.id;
      this.property.stateId = data.state?.id;
      this.property.cityId = data.city?.id;
      this.property.areaId = data.area?.id;
      this.property.buildingId = data.building?.id;
    },

    // ============================== API Related ==============================
    async submitForm() {
      this.$store.commit("setIsLoading", true);

      try {
        await this.$store.dispatch("manageProperty/createMyProperty", {
          payload: propertyModel.postPayload(this.property)
        });
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Property has been updated successfully."
        });

        this.$router.push({ name: "ManageProperty" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);

        let errorMessage = null;
        if (error.response != null) {
          errorMessage = {
            user: this.$auth.user().id,
            api: error.response.config.url,
            message:
              typeof error.response.data != "string"
                ? JSON.stringify(error.response.data)
                : error.response.data
          };
        } else {
          errorMessage = {
            user: this.$auth.user().id,
            message: error
          };
        }

        this.$reportError(JSON.stringify(errorMessage));

        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });

        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
